import { mapGetters } from 'vuex'
import item from './item/index.vue'

export default {
  name: 'setting-course-categories-countries',
  data () {
    return {
      checkboxes: []
    }
  },
  components: {
    item
  },
  computed: {
    ...mapGetters({
      basicCountries: 'setting/basicCountries',
      currentItem: 'settingCourseCategories/currentItem',
    })
  },
  created () {
    this.checkboxes.push(...this.currentItem.countries.data.map(e => e.countryId))
  }
}
